import { render, staticRenderFns } from "./Hero.vue?vue&type=template&id=3d52c0b6&scoped=true"
import script from "./Hero.vue?vue&type=script&lang=js"
export * from "./Hero.vue?vue&type=script&lang=js"
import style0 from "./Hero.vue?vue&type=style&index=0&id=3d52c0b6&prod&lang=scss"
import style1 from "./Hero.vue?vue&type=style&index=1&id=3d52c0b6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d52c0b6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconCarouselArrowLeft: require('/opt/build/repo/components/icons/IconCarouselArrowLeft.vue').default,HeroSlide: require('/opt/build/repo/components/common/HeroSlide.vue').default,IconCarouselArrowRight: require('/opt/build/repo/components/icons/IconCarouselArrowRight.vue').default})
