
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

import { mapState } from "vuex";

export default {
  props: {
    blok: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      slideAspectRatios: {},
    };
  },
  components: {
    VueSlickCarousel,
  },
  mounted() {
    this.$nextTick(() => {
      this.equalizeSlideHeights();

      if (!this.isMobile && process.client) {
        this.blok.slides.forEach((slide) => {
          const img = new Image();
          img.onload = () => {
            this.$set(
              this.slideAspectRatios,
              slide.background_image?.filename,
              `${img.width} / ${img.height}`
            );
          };
          img.src = slide.background_image?.filename;
        });
      }

      window.addEventListener("resize", this.equalizeSlideHeights);
      window.addEventListener("orientationchange", this.equalizeSlideHeights);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.equalizeSlideHeights);
    window.removeEventListener("orientationchange", this.equalizeSlideHeights);
  },
  computed: {
    ...mapState(["location", "user"]),

    carouselSettings() {
      return {
        arrows: false,
        slidesToShow: 1,
        draggable: !this.isOneSlide,
        swipe: !this.isOneSlide,
        dots: !this.isOneSlide,
        arrows: !this.isMobile && !this.isOneSlide,
      };
    },
    isMobile() {
      return this.$mq == "sm" || this.$mq == "md";
    },
    slides() {
      return this.blok.slides.map((slide) => {
        return {
          ...slide,
          // if is mobile and mobile background image exists, use it, otherwise use desktop background image
          backgroundImage:
            this.isMobile &&
            slide.mobile_background_image &&
            slide.mobile_background_image.filename
              ? slide.mobile_background_image
              : slide.background_image,
          aspectRatio: this.slideAspectRatios[slide.background_image?.filename],
        };
      });
    },
    isOneSlide() {
      return this.slides.length === 1;
    },
    isBackgroundOnly() {
      return this.slides.every(
        (slide) => !slide.title && !slide.text_content && !slide.buttons.length
      );
    },
  },
  methods: {
    getSlideStyles(slide) {
      const styles = {
        backgroundImage: `url(${slide.backgroundImage.filename}/m/)`,
      };

      if (!this.isMobile && slide.aspectRatio) {
        styles.aspectRatio = slide.aspectRatio;
      }

      return styles;
    },
    equalizeSlideHeights() {
      if (!this.isMobile && this.blok?.natural_image_size) return;

      this.$nextTick(() => {
        let maxHeight = 0;
        const slides = this.$refs.carousel.$el.querySelectorAll(".hero-slides");

        // Reset all heights
        slides.forEach((slide) => {
          slide.style.height = "auto";
        });

        // Find max height
        slides.forEach((slide) => {
          if (slide.offsetHeight > maxHeight) {
            maxHeight = slide.offsetHeight;
          }
        });

        // Set max height to all slides
        slides.forEach((slide) => {
          slide.style.height = maxHeight + "px";
        });
      });
    },
  },
};
