
import { mapState } from "vuex";

export default {
  props: {
    isOneSlide: {
      type: Boolean,
      default: false
    },
    slide: {
      type: Object,
      default: () => { }
    },
  },
  computed: {
    ...mapState(["location", "user"]),
    filteredButtons() {
      if (!this.slide?.buttons) return ''
      const selectedLocation = !!this.location
      const loggedIn = !!this.user

      return selectedLocation ? this.slide.buttons.filter((b) => loggedIn ? b.logged_in_state : b.hot_state) : loggedIn ? this.slide.buttons.filter((b) => b.logged_in_state) : this.slide.buttons
    },
    buttonOnly() {
      return this.filteredButtons.length > 0 && !this.slide.title && !this.slide.text_content
    },
  },
  methods: {
    slideHeading(str) {
      const index = str.indexOf("™");
      if (index !== -1) str = str.slice(0, index) + "<sup>" + str.slice(index) + "</sup>";
      return str
    }
  },
}
